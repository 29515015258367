import clsx from 'clsx';
import { useMatch } from 'react-router-dom';
import {
  OnboardingSection,
  OnboardingSuperflowStep,
} from '@features/uiOnboarding/utils';
import { OnboardingTooltip } from '@features/uiOnboarding/Tooltip/OnboardingTooltip';
import { Path } from 'src/app/router/routes';
import { useWorkspaceId } from 'src/utils/resource.hooks';
import { workspaceList } from 'src/app/components/workspace/WorkspaceSidebar/WorkspaceList.css';
import {
  useWorkspace,
  useWorkspaceCol,
} from 'src/services/database/Workspaces/getters';
import { SvgPlus } from 'src/app/components/workspace/WorkspaceSidebar/menus/SvgPlus';
import { useTypedNavigate } from 'src/app/router';
import { useIsGuestUser, useIsUserLoggedIn } from 'src/utils/auth/hooks';
import { Tooltip } from 'src/app/components/shared/Tooltip';
import { useBuiltPathMatch } from 'src/app/router/utils';
import { vars } from 'src/styles/vars.css';
import { Flex } from '../../shared/Flex';
import {
  sidebarButton,
  sidebarButtonActive,
  sidebarButtonSlot,
  sidebarVars,
} from './Sidebar.css';
import { WorkspaceLink } from './WorkspaceLink';

export function WorkspaceList() {
  const isLoggedIn = useIsUserLoggedIn();
  const isGuestUser = useIsGuestUser();

  const activeWorkspaceId = useWorkspaceId();
  const activeWorkspace = useWorkspace();
  const otherWorkspaces = useWorkspaceCol()
    .result?.docs?.map((doc) => doc.data())
    .filter((ws) => ws.slug !== activeWorkspace?.slug);

  const navigate = useTypedNavigate();
  const shouldShowHomeIcon = useBuiltPathMatch(Path.FlowInstance) !== null;
  const isCreateWorkspaceActive = useMatch(Path.WorkspaceCreate);

  return (
    <Flex
      direction="column"
      gap="8px"
      align="center"
      justify="flex-start"
      style={{ flex: '1 0 auto', marginBottom: '8px' }}
    >
      {activeWorkspace ? (
        <OnboardingTooltip
          section={OnboardingSection.Superflow}
          step={OnboardingSuperflowStep.Workspace}
          text="Click here to go to your current workspace."
          placement="bottom"
        >
          <Flex className={sidebarButtonSlot}>
            <WorkspaceLink
              name={activeWorkspace.name}
              workspaceId={activeWorkspaceId}
              imageUrl={activeWorkspace.logo}
              size="medium"
              showHomeIcon={shouldShowHomeIcon}
            />
          </Flex>
        </OnboardingTooltip>
      ) : null}
      {activeWorkspace && otherWorkspaces && otherWorkspaces.length > 0 ? (
        <div
          style={{
            width: sidebarVars.buttonSize,
            height: '1px',
            margin: '0 auto',
            borderRadius: '1px',
            background: vars.color.light84,
          }}
        />
      ) : null}
      {otherWorkspaces !== undefined && otherWorkspaces.length > 0 ? (
        <Flex
          direction="column"
          gap="8px"
          align="center"
          justify="flex-start"
          className={workspaceList}
        >
          {otherWorkspaces?.map((ws) => (
            <Flex key={ws.slug} className={sidebarButtonSlot}>
              <WorkspaceLink
                name={ws.name}
                workspaceId={ws.slug}
                imageUrl={ws.logo}
                size="small"
              />
            </Flex>
          ))}
        </Flex>
      ) : null}

      {isLoggedIn && !isGuestUser ? (
        <Flex className={sidebarButtonSlot}>
          <Tooltip.Root placement="right">
            <Tooltip.Anchor>
              <OnboardingTooltip
                section={OnboardingSection.Superflow}
                step={OnboardingSuperflowStep.CreateWorkspace}
                text="You can create a new workspace by clicking here."
                placement="bottom"
              >
                <Flex
                  as="button"
                  type="button"
                  onClick={() => navigate(Path.WorkspaceCreate)}
                  title="Create workspace"
                  className={clsx(
                    sidebarButton,
                    isCreateWorkspaceActive && sidebarButtonActive,
                  )}
                >
                  <SvgPlus
                    style={{
                      display: 'block', // Gets rid of SVG's phantom line height
                      width: '24px',
                      height: '24px',
                      color: 'inherit',
                    }}
                  />
                </Flex>
              </OnboardingTooltip>
            </Tooltip.Anchor>
            {!isCreateWorkspaceActive && (
              <Tooltip.Content>Create a new workspace</Tooltip.Content>
            )}
          </Tooltip.Root>
        </Flex>
      ) : null}
    </Flex>
  );
}
