import { PropsWithChildren, useCallback, useEffect } from 'react';
import {
  OnboardingSection,
  OnboardingStep,
} from '@features/uiOnboarding/utils';
import { useOnboardingContext } from '@features/uiOnboarding/OnboardingProvider';
import { useSingleActionModal } from 'src/app/components/modals/SingleActionModal/SingleActionModal';
import useAsync from 'src/utils/useAsync';

interface IOnboardingModalProps {
  section: OnboardingSection;
  step: OnboardingStep;
  modalTitle: string;
  modalActionButtonText?: string;
}

/**
 * Wrapper for any given onboarding modal. This component will only render
 * if the current step matches the step and section provided.
 *
 * @param section - Section to check for visibility.
 * @param step - Step to check within section for visibility.
 * @param modalTitle - Title of the modal.
 * @param modalActionButtonText - Action of the modal, defaults to "OK".
 * @param children - Child elements of the modal, e.g. slideshow, text, etc.
 * @constructor
 */
export function OnboardingModal({
  section,
  step,
  modalTitle,
  modalActionButtonText = 'OK',
  children,
}: PropsWithChildren<IOnboardingModalProps>) {
  const { completeStep, canActivateStep, activateStep, deactivateStep } =
    useOnboardingContext();

  const complete = useAsync(async () => {
    completeStep(section, step);
    return true;
  });

  const onClose = useCallback(() => complete.execute(), [complete]);

  const modal = useSingleActionModal({
    modalTitle,
    actionButtonText: modalActionButtonText,
    cancelButtonText: 'Skip',
    request: complete,
    isSubmitAllowed: true,
    hideActionButtonOnSubmit: false,
    isCloseAllowed: false,
    submitOnShow: false,
    modalContent: () => children,
    onClose,
  });

  const isVisible = canActivateStep(section, step);

  useEffect(() => {
    if (isVisible) {
      modal.functions.show();
      activateStep(section, step);
    }

    return () => deactivateStep(section, step);
  }, [activateStep, deactivateStep, isVisible, modal, section, step]);

  return isVisible ? <>{modal.element}</> : null;
}
