import { ComponentProps, CSSProperties, forwardRef } from 'react';
import { InputFieldBase } from '../InputFieldBase/InputFieldBase';
import { TextBoxInput } from './TextBoxInput';

type ITextBoxProps = ComponentProps<typeof TextBoxInput> &
  Pick<
    ComponentProps<typeof InputFieldBase>,
    'error' | 'isLabelRendered' | 'label' | 'labelStyle'
  > & {
    /**
     * Applies to the root of `<InputField/>`.
     *
     * To access the enclosed `<Textbox/>`, see the following props:
     * `wrapperClassName, inputClassName`.
     */
    groupClassName?: string;
    /**
     * Applies to the root of `<InputField/>`.
     *
     * To access the enclosed `<Textbox/>`, see the following props:
     * `wrapperStyle, inputStyle`.
     */
    groupStyle?: CSSProperties;
  };

/**
 * Wraps the basic {@link TextBoxInput} with {@link InputFieldBase}
 * so the label and error are also rendered along with the input component.
 *
 * To override the field's colors, import the CSS variables from `cssVarsInputField`.
 *
 * @example
 * ```tsx
 * const { getFieldState, handleSubmit, register } = useForm<{ username: string }>()
 * return (
 *  <form onSubmit={handleSubmit(console.log)}>
 *    <TextBox
 *      required // This renders the red asterisk in the field label
 *      {...register('username', { required: true })} // This implements the required validation
 *      error={getFieldState('username').error.message}
 *    />
 *  </form>
 * )
 * ```
 */
const TextBox = forwardRef<HTMLInputElement, ITextBoxProps>(function TextBox(
  {
    'aria-describedby': describedBy,
    error,
    groupClassName,
    groupStyle,
    isLabelRendered,
    label,
    labelStyle,
    required,
    wrapperClassName,
    ...inputProps
  },
  ref,
) {
  return (
    <InputFieldBase
      error={error}
      required={required}
      isLabelRendered={isLabelRendered}
      label={label}
      input={(inputId, labelId, errorId, hasError) => (
        <TextBoxInput
          ref={ref}
          id={inputId}
          required={required}
          hasError={hasError}
          aria-labelledby={labelId}
          aria-describedby={hasError ? errorId : describedBy}
          wrapperClassName={wrapperClassName}
          wrapperStyle={{ width: '100%' }}
          {...inputProps}
        />
      )}
      className={groupClassName}
      style={groupStyle}
      labelStyle={labelStyle}
    />
  );
});

export default TextBox;
