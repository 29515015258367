import { create, NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { JSX, ReactNode, useCallback, useMemo, useRef } from 'react';
import { v4 } from 'uuid';
import { AsyncStatus, IAsyncResponse } from 'src/utils/useAsync';
import { AnimatedModal } from 'src/app/components/modals/AnimatedModal';
import { shareModalTheme } from 'src/app/pages/Development/FlowDefinition/History/ShareModal/ShareModal.css';
import { ModalCloseButton } from 'src/app/components/modals/ModalCloseButton/ModalCloseButton';
import { Flex } from 'src/app/components/shared/Flex';
import { typography } from 'src/styles/typography.css';
import { ButtonPill } from 'src/app/components/shared/ButtonPill';
import { InputError } from 'src/app/components/form/InputError/InputError';

interface ISingleActionModalOptions {
  /** Title shown in the modal header */
  modalTitle: string;
  /** Text shown on the action button */
  actionButtonText: string;
  /** Text shown on the cancel button */
  cancelButtonText: string;
  /** Request to be executed when the action button is clicked */
  request: IAsyncResponse<unknown>;
  /** Whether the request can be submitted */
  isSubmitAllowed: boolean;
  /** Whether the action button is shown on submit */
  hideActionButtonOnSubmit?: boolean;
  /** Whether the modal can be closed by clicking the cancel button, the modal takes this in addition to checking the request status */
  isCloseAllowed: boolean;
  /** Whether the request should be submitted on show */
  submitOnShow?: boolean;
  /** Content renderer of the modal */
  modalContent: () => ReactNode;
  /** On close callback */
  onClose?: () => void;
}

export interface ISingleActionModalForwardRefFunctions<T = unknown> {
  /** Show the modal and optionally pass data to the modal */
  show: (data?: T) => void;
  /** Request to close the modal */
  requestClose: () => void;
}

interface ISingleActionModalHookResponse {
  element: JSX.Element;
  functions: ISingleActionModalForwardRefFunctions;
}

interface ISingleActionModalProps extends ISingleActionModalOptions {
  execute: () => void;
  requestClose: () => void;
}

const SingleActionModal = create(function SingleActionModalFactory({
  id,
  modalTitle,
  actionButtonText,
  cancelButtonText,
  request,
  requestClose,
  execute,
  modalContent,
  isSubmitAllowed,
  hideActionButtonOnSubmit,
  isCloseAllowed,
}: ISingleActionModalProps & NiceModalHocProps) {
  const content = useMemo(() => modalContent(), [modalContent]);
  const isPending = request.status === AsyncStatus.Pending;

  return (
    <AnimatedModal
      modalId={id}
      onRequestClose={requestClose}
      modalProps={{
        shouldCloseOnOverlayClick: false,
        shouldCloseOnEsc: false,
        style: {
          content: {
            position: 'relative',
            width: '520px',
            maxWidth: '98vw',
            maxHeight: '98vh',
            padding: '2em 4em',
          },
        },
      }}
      themeClassName={shareModalTheme}
    >
      {isCloseAllowed ? <ModalCloseButton onClick={requestClose} /> : null}
      <Flex gap={24} direction="column" align="center">
        <h1 className={typography.h3}>{modalTitle}</h1>

        {content}

        <Flex
          align="center"
          justify="space-between"
          gap={16}
          style={{ width: '100%' }}
        >
          <div>
            {isCloseAllowed ? (
              <ButtonPill
                intent="secondary"
                theme="dark"
                onClick={requestClose}
              >
                {cancelButtonText}
              </ButtonPill>
            ) : null}
          </div>
          {!hideActionButtonOnSubmit || !isPending ? (
            <ButtonPill
              onClick={execute}
              disabled={!isSubmitAllowed}
              loading={isPending}
            >
              {actionButtonText}
            </ButtonPill>
          ) : null}
        </Flex>
        {request.error ? (
          <p style={{ textAlign: 'center' }}>
            <InputError severity="blocking">{request.error + ''}</InputError>
          </p>
        ) : null}
      </Flex>
    </AnimatedModal>
  );
});

/**
 * Single action modal component hook. Use this when you want to create a modal
 * based on a `useAsync` hook. It will handle one action, and a cancel button.
 * It will also have a specific theme.
 */
export function useSingleActionModal(
  options: ISingleActionModalOptions,
): ISingleActionModalHookResponse {
  const modalIdRef = useRef('single-action-modal-' + v4());
  const modal = useModal(modalIdRef.current);
  const onClose = useCallback(() => {
    modal.hide();
    if (options.onClose) {
      options.onClose();
    }
  }, [modal, options]);

  const isPending = options.request.status === AsyncStatus.Pending;
  const isCloseAllowedWithValidation = options.isCloseAllowed && !isPending;
  const requestClose = useCallback(() => {
    if (isCloseAllowedWithValidation) {
      onClose();
    }
  }, [isCloseAllowedWithValidation, onClose]);

  const execute = useCallback(() => {
    if (options.request.status === AsyncStatus.Pending) {
      return;
    }

    options.request.execute().then(() => onClose());
  }, [options.request, onClose]);

  const show = useCallback(() => {
    if (modal.visible) {
      return;
    }

    options.request.reset();
    if (options.submitOnShow) {
      execute();
    }

    modal.show();
  }, [modal, options.request, options.submitOnShow, execute]);

  const element = (
    <SingleActionModal
      {...options}
      id={modalIdRef.current}
      requestClose={requestClose}
      execute={execute}
      isCloseAllowed={isCloseAllowedWithValidation}
    />
  );

  return {
    element,
    functions: {
      show,
      requestClose,
    },
  };
}
