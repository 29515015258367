import { PropsWithChildren } from 'react';
import {
  OnboardingSection,
  OnboardingTemplateStep,
} from '@features/uiOnboarding/utils';
import { OnboardingModal } from '@features/uiOnboarding/Modal/OnboardingModal';

/**
 * This modal is rendered when the user lands on the Templates page the first
 * time, or if they didn't complete the onboarding for the Templates page.
 */
export function TemplatesOnboarding({ children }: PropsWithChildren) {
  return (
    <>
      {children}

      <OnboardingModal
        section={OnboardingSection.Template}
        step={OnboardingTemplateStep.WelcomeModal}
        modalTitle="Use templates to create flows 🌟"
        modalActionButtonText="Let's do it"
      >
        <p>
          Templates are pre-built flows that you can use to get started. After
          you duplicate a template using the {'"'}Use template{'"'} button, you
          can edit them to fit your needs.
        </p>
        <p>
          You{"'"}ll be able to edit the basic details, and sessions of your
          flows, including activities and widgets.
        </p>
      </OnboardingModal>
    </>
  );
}
