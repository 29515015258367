import clsx from 'clsx';
import { motion, useWillChange } from 'framer-motion';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import { modalOverlay } from './AnimatedModal.css';

// These props are handpicked because simply spreading all props
// would cause a TS error due to Framer Motion's unique animation callbacks.
type ReactModalProps = Pick<
  HTMLAttributes<HTMLElement>,
  'className' | 'onClick' | 'onMouseDown'
>;

export const AnimatedOverlay = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ReactModalProps>
>(function AnimatedOverlay({ children, className, ...props }, ref) {
  const willChange = useWillChange();
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      {...props}
      className={clsx(className, modalOverlay)}
      style={{ willChange }}
    >
      {children}
    </motion.div>
  );
});
