import { Provider as ModalProvider } from '@ebay/nice-modal-react';
import { useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { useHydrateOnboardingEffect } from '@features/onboarding';
import { OnboardingProvider } from '@features/uiOnboarding/OnboardingProvider';
import { useTrackPageTransitionsEffect } from 'src/services/analytics';
import { useActiveStoreInit } from 'src/state/active/hooks';
import { useCheckIfMobileEffect } from 'src/state/device';
import { isTouchDevice, isUserAgentTabletOrMobile } from 'src/utils/device';
import { useHandleFlowSwitch } from 'src/utils/flow/hooks';
import useUserAuthState, { UserAuthStatus } from '../utils/auth/hooks';

const hasTouchBackend = isTouchDevice() && isUserAgentTabletOrMobile();

/*
 Flow context lifted because of the following uses:
  - Admin panel PDF generation
  - Avoiding giving lots of props to modal components due to them being separate from the main flow
*/

function App() {
  useActiveStoreInit();
  useHandleFlowSwitch();
  useTrackPageTransitionsEffect();
  useCheckIfMobileEffect();
  useHydrateOnboardingEffect();

  const userStatus = useUserAuthState();
  const canRender = useMemo(
    () =>
      userStatus === UserAuthStatus.Success ||
      userStatus === UserAuthStatus.Failed,
    [userStatus],
  );

  // TODO Loading state

  return canRender ? (
    <ModalProvider>
      <DndProvider backend={hasTouchBackend ? TouchBackend : HTML5Backend}>
        <OnboardingProvider>
          <Helmet defaultTitle="Superflow" titleTemplate="%s | Superflow" />
          <Outlet />
        </OnboardingProvider>
      </DndProvider>
    </ModalProvider>
  ) : null;
}

export default App;
