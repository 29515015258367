import { CSSProperties, useMemo } from 'react';
import clsx from 'clsx';
import {
  animatedDotIndicatorContainerCss,
  animatedDotIndicatorDotCss,
  animatedDotIndicatorLargeCss,
  animatedDotIndicatorPulseCss,
  animatedDotIndicatorRegularCss,
  animatedDotIndicatorSmallCss,
} from 'src/app/components/shared/AnimatedDotIndicator/AnimatedDotIndicator.css';
import { brandColor } from 'src/styles/colors/brand';

export enum AnimatedDotIndicatorSize {
  Small = 'small',
  Regular = 'regular',
  Large = 'large',
}

interface IAnimatedDotIndicatorProps {
  /** Size of the dot indicator. */
  size?: AnimatedDotIndicatorSize;
  /** Color of the dot indicator */
  color?: CSSProperties['color'];
  /** Optional class name to apply */
  className?: string;
  /** Optional styling. This will override all other styling, including size, color, and class-based styling if set in this prop. */
  style?: CSSProperties;
}

/**
 * This component is an animated dot indicator, rendering a small circle with a
 * larger circle pulsing outside of it. It's used for badges, onboarding, etc.
 * @constructor
 */
export function AnimatedDotIndicator({
  size = AnimatedDotIndicatorSize.Regular,
  color = brandColor.purple,
  className,
  style,
}: IAnimatedDotIndicatorProps) {
  const sizeClassName = useMemo(() => {
    switch (size) {
      case AnimatedDotIndicatorSize.Small: {
        return animatedDotIndicatorSmallCss;
      }
      case AnimatedDotIndicatorSize.Large: {
        return animatedDotIndicatorLargeCss;
      }
      default: {
        return animatedDotIndicatorRegularCss;
      }
    }
  }, [size]);

  return (
    <div
      className={clsx(
        animatedDotIndicatorContainerCss,
        sizeClassName,
        className,
      )}
      style={style}
    >
      <div
        className={animatedDotIndicatorPulseCss}
        style={{ backgroundColor: color }}
      />
      <div
        className={animatedDotIndicatorDotCss}
        style={{ backgroundColor: color }}
      />
    </div>
  );
}
