import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import App from 'src/app/App';
import { RedirectToFirstWorkspace } from 'src/app/pages/RedirectToFirstWorkspace';
import { AuthRouteGuard } from 'src/app/router/guards/AuthRouteGuard';
import { EnsureWorkspaceGuard } from 'src/app/router/guards/EnsureWorkspaceGuard';
import { WorkspaceAccessRouteGuard } from 'src/app/router/guards/WorkspaceAccessRouteGuard';
import { WorkspaceTagRouteGuard } from 'src/app/router/guards/WorkspaceTagRouteGuard';
import { LazyComponent } from 'src/app/router/Lazy/LazyComponent';
import {
  createBaseRoute,
  createIndexRoute,
  createLazyRoute,
} from 'src/app/router/utils';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';
import { AclRouterGuard } from 'src/services/acl/aclRouterGuard';
import { AclAccessMode } from 'src/services/acl/aclApi';
import { AclResource } from 'src/services/acl/aclResource';
import { FlowInstanceAclGuard } from 'src/app/router/guards/FlowInstanceAclGuard';
import FlowInstanceAndSessionHandlerPage from 'src/app/pages/FlowInstanceAndSessionHandler';
import { TemplatesOnboarding } from 'src/app/components/onboarding/TemplatesOnboarding';
import { SuperflowOnboarding } from 'src/app/components/onboarding/SuperflowOnboarding';
import Error500 from '../pages/Error500';
import { SessionAccessGuard } from './guards/SessionAccessGuard';
import { SessionEditorAccessGuard } from './guards/SessionEditorAccessGuard';
import { Path } from './routes';

/**
 * Router configuration that includes all the routes in the application.
 * Currently, it uses absolute paths using the {@link Path} enum, but the
 * routes are nested in a way that allows for relative paths as well.
 */
const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    errorElement: <Error500 />,
    children: [
      {
        path: Path.Root,
        element: (
          <AuthRouteGuard>
            <EnsureWorkspaceGuard>
              <RedirectToFirstWorkspace />
            </EnsureWorkspaceGuard>
          </AuthRouteGuard>
        ),
      },
      {
        path: Path.WorkspaceCreate,
        element: (
          <AuthRouteGuard>
            <LazyComponent
              importFunc={() => import('../pages/CreateWorkspace')}
            />

            <SuperflowOnboarding />
          </AuthRouteGuard>
        ),
      },
      {
        path: Path.WorkspaceJoinViaInvitation,
        element: (
          <LazyComponent
            importFunc={() => import('../pages/WorkspaceInvite')}
          />
        ),
      },
      {
        path: Path.Workspace,
        element: (
          <AuthRouteGuard>
            <EnsureWorkspaceGuard>
              <WorkspaceAccessRouteGuard>
                <Outlet />
                <SuperflowOnboarding />
              </WorkspaceAccessRouteGuard>
            </EnsureWorkspaceGuard>
          </AuthRouteGuard>
        ),
        children: [
          createBaseRoute(Path.Workspace, () => import('../pages/Workspace'), [
            // This is a catch-all route for the /workspace/:workspaceId path
            // that will redirect to the correct page based on the workspace
            // tags.
            createIndexRoute(
              () => import('src/app/pages/Workspace/RedirectPage'),
            ),
            {
              path: Path.FlowEditorRoot,
              element: (
                <AclRouterGuard resources={[AclResource.FlowDefinitionEdit]}>
                  <Outlet />
                </AclRouterGuard>
              ),
              children: [
                createIndexRoute(
                  () =>
                    import(
                      '../pages/Flow/settings/FlowSettingsPage/FlowSettingsPage'
                    ),
                ),
              ],
            },
            {
              path: Path.TemplatesRoot,
              element: (
                <AclRouterGuard
                  resources={[AclResource.FlowDefinitionCreate]}
                  mode={AclAccessMode.Any}
                >
                  <TemplatesOnboarding>
                    <Outlet />
                  </TemplatesOnboarding>
                </AclRouterGuard>
              ),
              children: [
                createIndexRoute(() => import('src/app/pages/Templates')),
              ],
            },
            {
              path: Path.AcquisitionRoot,
              element: (
                <WorkspaceTagRouteGuard
                  requiredTags={[FlowDefinitionTag.Acquisition]}
                >
                  <Outlet />
                </WorkspaceTagRouteGuard>
              ),
              children: [
                createBaseRoute(Path.AcquisitionRoot, [
                  createLazyRoute(
                    Path.AcquisitionOpenings,
                    () => import('src/app/pages/Acquisition/Home'),
                  ),
                ]),
              ],
            },
            createBaseRoute(Path.AcquisitionFlowDefinition, [
              createIndexRoute(
                () => import('src/app/pages/Acquisition/FlowDefinition'),
              ),
            ]),
            createBaseRoute(Path.DevelopmentRoot, [
              createLazyRoute(
                Path.DevelopmentFlows,
                () => import('../pages/Development/Home'),
              ),
            ]),
            createBaseRoute(
              Path.DevelopmentFlowDefinition,
              () => import('src/app/pages/Development/FlowDefinition'),
              [
                createIndexRoute(
                  () =>
                    import(
                      '../pages/Development/FlowDefinition/Overview/DevelopmentFlowDefinitionOverviewPage'
                    ),
                ),
                createLazyRoute(
                  Path.DevelopmentFlowDefinitionHistory,
                  () =>
                    import(
                      'src/app/pages/Development/FlowDefinition/History/DevelopmentFlowDefinitionHistoryPage'
                    ),
                ),
              ],
            ),
          ]),

          {
            path: Path.FlowInstance,
            element: (
              <FlowInstanceAclGuard>
                <FlowInstanceAndSessionHandlerPage />
              </FlowInstanceAclGuard>
            ),
            children: [
              createIndexRoute(
                () => import('../pages/FlowInstance/FlowInstance'),
              ),
              {
                path: Path.Session,
                element: (
                  <SessionAccessGuard>
                    <LazyComponent
                      importFunc={() => import('../pages/Session')}
                    />
                  </SessionAccessGuard>
                ),
              },
            ],
          },
        ],
      },

      // Session editor outside of workspace base route to avoid rendering workspace sidebars
      {
        path: Path.FlowEditorSession,
        element: (
          <AuthRouteGuard>
            <EnsureWorkspaceGuard>
              <WorkspaceAccessRouteGuard>
                <SessionEditorAccessGuard>
                  <LazyComponent
                    importFunc={() =>
                      import(
                        '../pages/Flow/settings/SessionSettingsPage/SessionSettingsPage'
                      )
                    }
                  />
                </SessionEditorAccessGuard>
              </WorkspaceAccessRouteGuard>
            </EnsureWorkspaceGuard>
          </AuthRouteGuard>
        ),
      },

      createBaseRoute(
        Path.SharedEmbeddedFlowInstance,
        () => import('src/app/pages/ShareLandingPage'),
        [
          createIndexRoute(
            () => import('../pages/Development/FlowInstance/Embedded'),
          ),
          createLazyRoute(
            Path.SharedEmbeddedSession,
            () => import('../pages/Session'),
          ),
        ],
      ),
      createBaseRoute(
        Path.AcquisitionInvitationEmbeddedFlowInstance,
        () => import('../pages/Acquisition/InvitationEmbeddedFlowInstance'),
        [
          createIndexRoute(
            () => import('../pages/Acquisition/FlowInstance/Embedded'),
          ),
          createLazyRoute(
            Path.CandidateEmbeddedSession,
            () => import('../pages/Session'),
          ),
        ],
      ),
      createLazyRoute(Path.GuestSignup, () => import('../pages/GuestSignup')),
      createLazyRoute(
        Path.ActionHandlerAction,
        () => import('../pages/EmailActionHandler'),
      ),
      // #region Internal Flow Editor
      {
        path: Path.FlowEditorHome,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/FlowEditorHome/FlowEditorHome'
              )
            }
          />
        ),
      },
      {
        path: Path.FlowVersionEditor,
        element: (
          <LazyComponent
            importFunc={() =>
              import('../features/flowEditor/pages/FlowEditor/FlowEditor')
            }
          />
        ),
      },
      {
        path: Path.FlowVersionSessionBundles,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/FlowEditor/SessionDrafts/SessionDrafts'
              )
            }
          />
        ),
      },
      {
        path: Path.FlowVersionModules,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/FlowEditor/ModuleEditor/ModuleEditor'
              )
            }
          />
        ),
      },
      {
        path: Path.FlowVersionSettings,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/FlowEditor/FlowSettings/FlowSettings'
              )
            }
          />
        ),
      },
      {
        path: Path.SessionEditor,
        element: (
          <LazyComponent
            importFunc={() =>
              import('../features/flowEditor/pages/SessionEditor/SessionEditor')
            }
          />
        ),
      },
      {
        path: Path.SessionEditorPreview,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/SessionPreview/SessionPreview'
              )
            }
          />
        ),
      },
      // #endregion Internal Flow Editor
      {
        path: Path.SystemAdmin,
        element: (
          <AuthRouteGuard>
            <Outlet />
          </AuthRouteGuard>
        ),
        children: [
          createBaseRoute(Path.SystemAdmin, () => import('../pages/Admin'), [
            createLazyRoute(
              Path.SystemAdminFlows,
              () => import('../pages/Admin/Flows'),
            ),
            createLazyRoute(
              Path.SystemAdminWorkspaces,
              () => import('../pages/Admin/Workspaces'),
            ),
            createLazyRoute(
              Path.SystemAdminBilling,
              () => import('../pages/Admin/Billing'),
            ),
          ]),
        ],
      },
      createLazyRoute(Path.Auth, () => import('../pages/Auth')),
      createLazyRoute(Path.NotFound, () => import('../pages/Error404')),
      createLazyRoute('*', () => import('../pages/Error404')),
    ],
  },
]);

/**
 * The main router component that provides the router context to the application.
 */
export function Router() {
  return <RouterProvider router={router} />;
}
