import { onboardingReducer as onboarding } from '@features/onboarding';
import { reducer as uiOnboarding } from '@features/uiOnboarding/state';
import { reducer as active } from './active';
import { activeSession } from './activeSession';
import { reducer as device } from './device';
import { flow } from './flow';
import { reducer as sidebar } from './sidebar/sidebar';
import user from './user';

/**
 * Stored as separate variable, outside `configureStore()`
 * for the benefit of `IRootState`.
 * If `store.middleware` is set, the return type of `store.getState()`
 * falls back to `any` for some reason, which breaks `IRootState`.
 */
export const staticReducers = {
  active,
  activeSession,
  device,
  flow,
  onboarding,
  user,
  sidebar,
  uiOnboarding,
};
