import {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Placement } from '@floating-ui/react';
import { useOnboardingContext } from '@features/uiOnboarding/OnboardingProvider';
import {
  OnboardingSection,
  OnboardingStep,
} from '@features/uiOnboarding/utils';
import {
  onboardingTooltipAnchorCss,
  onboardingTooltipContentCss,
  onboardingTooltipCss,
} from '@features/uiOnboarding/Tooltip/OnboardingTooltip.css';
import { Tooltip } from 'src/app/components/shared/Tooltip';
import { Flex } from 'src/app/components/shared/Flex';
import {
  AnimatedDotIndicator,
  AnimatedDotIndicatorSize,
} from 'src/app/components/shared/AnimatedDotIndicator/AnimatedDotIndicator';
import { vars } from 'src/styles/vars.css';
import { linkText } from 'src/styles/global.css';
import { unstyledButton } from 'src/styles/utils.css';

interface IOnboardingTooltipProps {
  /** Onboarding section this tooltip belongs to. */
  section: OnboardingSection;
  /** Onboarding step this tooltip belongs to. */
  step: OnboardingStep;
  /** Text used in the tooltip. */
  text: string;
  /** Placement of the tooltip relative to the anchor element. */
  placement?: Placement;
  /** Optional styling. */
  style?: CSSProperties;
}

/**
 * Tooltip component used for any onboarding step. It will only render if the
 * current step matches the step and section provided. Any children will be
 * rendered as the tooltip anchor. The tooltip will only be closed if the user
 * clicks on the tooltip content, which in turn will trigger the complete step
 * action.
 */
export function OnboardingTooltip({
  section,
  step,
  text,
  placement = 'bottom',
  style,
  children,
}: PropsWithChildren<IOnboardingTooltipProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const {
    isLastStep,
    completeStep,
    skipSection,
    canActivateStep,
    activateStep,
    deactivateStep,
  } = useOnboardingContext();

  const onSkipSection = () => skipSection(section);
  const isShown = canActivateStep(section, step);

  useEffect(() => {
    if (isShown) {
      activateStep(section, step);
      if (!isScrolled && !ref.current) {
        requestAnimationFrame(() => {
          ref.current?.scrollIntoView({ behavior: 'smooth' });
          setIsScrolled(true);
        });
      }
    }

    return () => deactivateStep(section, step);
  }, [ref, isScrolled, isShown, activateStep, section, step, deactivateStep]);

  if (!isShown) {
    return <>{children}</>;
  }

  const isLast = isLastStep(section, step);

  return (
    <Tooltip.Root placement={placement} offsetPx={5} initialOpen isOpen>
      <Tooltip.Anchor style={style}>
        <div
          ref={ref}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => completeStep(section, step)}
          className={onboardingTooltipAnchorCss}
        >
          {children}
        </div>
      </Tooltip.Anchor>
      <Tooltip.Content className={onboardingTooltipCss}>
        <Flex
          gap={12}
          direction="row"
          align="center"
          as="button"
          onClick={() => completeStep(section, step)}
          className={onboardingTooltipContentCss}
        >
          <AnimatedDotIndicator
            size={AnimatedDotIndicatorSize.Small}
            color={vars.color.light99}
            style={{ flex: '0 0 auto' }}
          />
          <div style={{ textAlign: 'left' }}>{text}</div>
          {isLast ? null : (
            <button
              className={clsx(unstyledButton, linkText)}
              type="button"
              onClick={onSkipSection}
              style={{ padding: 0, margin: 0 }}
            >
              Skip
            </button>
          )}
        </Flex>
      </Tooltip.Content>
    </Tooltip.Root>
  );
}
