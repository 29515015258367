import { createSlice } from '@reduxjs/toolkit';
import store from 'store2';
import { OnboardingSection } from '@features/uiOnboarding/utils';
import { IRootState } from 'src/state/types';

// FIXME [KS] This is a temporary solution until we figure out what's wrong
//  with the other branch that has the collection-based approach.

const STORAGE_KEY = 'onboarding:completedStepsBySection';

export interface IOnboardingSectionState {
  completedSteps: string[];
  isSkipped: boolean;
}

export interface IOnboardingState {
  completedStepsBySection: Record<string, IOnboardingSectionState>;
}

/**
 * Get the completed steps by section from local storage. If the local storage
 * is not available, return an empty object.
 */
function getCompletedStepsBySectionFromLocalStorage() {
  return JSON.parse(store.get(STORAGE_KEY) || '{}');
}

/**
 * Save the completed steps by section to local storage.
 * @param completedStepsBySection - The completed steps by section.
 */
function saveCompletedStepsBySectionToLocalStorage(
  completedStepsBySection: Record<string, IOnboardingSectionState>,
) {
  store.set(STORAGE_KEY, JSON.stringify(completedStepsBySection));
}

export const defaultOnboardingState: IOnboardingState = {
  completedStepsBySection: getCompletedStepsBySectionFromLocalStorage(),
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: defaultOnboardingState,
  reducers: {
    completeStep: (
      state,
      action: { payload: { section: string; step: string } },
    ) => {
      const { section, step } = action.payload;
      if (!state.completedStepsBySection[section]) {
        state.completedStepsBySection[section] = {
          completedSteps: [],
          isSkipped: false,
        } as IOnboardingSectionState;
      }

      state.completedStepsBySection[section]!.completedSteps = [
        ...new Set<string>([
          ...state.completedStepsBySection[section]!.completedSteps,
          step,
        ]),
      ];
      saveCompletedStepsBySectionToLocalStorage(state.completedStepsBySection);
    },
    skipSection: (state, action: { payload: { section: string } }) => {
      const { section } = action.payload;
      if (!state.completedStepsBySection[section]) {
        state.completedStepsBySection[section] = {
          completedSteps: [],
          isSkipped: false,
        };
      }

      state.completedStepsBySection[section]!.isSkipped = true;
      saveCompletedStepsBySectionToLocalStorage(state.completedStepsBySection);
    },
    skipOnboarding: (state) => {
      const allSections = Object.values(OnboardingSection);
      allSections.forEach((section) => {
        state.completedStepsBySection[section] = {
          completedSteps: [],
          isSkipped: true,
        };
      });
      saveCompletedStepsBySectionToLocalStorage(state.completedStepsBySection);
    },
    resetOnboarding: (state) => {
      state.completedStepsBySection = {};
      store.remove(STORAGE_KEY);
    },
  },
});

export const { completeStep, skipSection, skipOnboarding, resetOnboarding } =
  onboardingSlice.actions;

export const selectCompletedStepsBySection = (state: IRootState) =>
  state.uiOnboarding.completedStepsBySection;

export const reducer = onboardingSlice.reducer;
