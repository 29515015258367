import { AnimationProps, motion, useWillChange, Variants } from 'framer-motion';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';

// These props are handpicked because simply spreading all props
// would cause a TS error due to Framer Motion's unique animation callbacks.
type ReactModalProps = Pick<
  HTMLAttributes<HTMLElement>,
  | 'aria-label'
  | 'aria-labelledby'
  | 'aria-modal'
  | 'id'
  | 'onClick'
  | 'onKeyDown'
  | 'onMouseDown'
  | 'onMouseUp'
  | 'role'
  | 'style'
  | 'tabIndex'
>;

const dropIn: Variants = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 40,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

export const AnimatedBody = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ReactModalProps & AnimationProps>
>(function AnimatedBody(props, ref) {
  const willChange = useWillChange();
  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      ref={ref}
      style={{ willChange }}
      {...props}
    />
  );
});
