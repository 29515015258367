import { IOnboardingSectionState } from '@features/uiOnboarding/state';

/** List of onboarding sections. */
export enum OnboardingSection {
  Superflow = 'superflow',
  Workspace = 'workspace',
  Template = 'template',
  TalentAcquisition = 'talent-acquisition',
  DevelopmentHub = 'development-hub',
  Editor = 'editor',
  EditorBasic = 'editor-basic',
  EditorModule = 'editor-module',
  EditorSession = 'editor-session',
  FlowInstance = 'flow-instance',
  FlowSession = 'flow-session',
}

export enum OnboardingSuperflowStep {
  WelcomeModal = 'welcome-modal',
  Support = 'support',
  Profile = 'profile',
  Feedback = 'feedback',
  Workspace = 'workspace',
  CreateWorkspace = 'create-workspace',
}

export enum OnboardingWorkspaceStep {
  WelcomeModal = 'welcome-modal',
  NavigationDevelopmentHub = 'navigation-development-hub',
  NavigationTalentAcquisition = 'navigation-talent-acquisition',
  NavigationTemplates = 'navigation-templates',
  UseTemplate = 'use-template',
  WorkspaceSettings = 'workspace-settings',
}

export enum OnboardingTemplateStep {
  WelcomeModal = 'welcome-modal',
  Area = 'area',
  WorkspaceTemplates = 'workspace-templates',
  GlobalTemplates = 'global-templates',
}

export enum OnboardingTalentAcquisitionStep {
  WelcomeModal = 'welcome-modal',
  Editing = 'editing',
  InviteCandidates = 'invite-candidates',
  EvaluateCandidates = 'evaluate-candidates',
  Applications = 'applications',
}

export enum OnboardingDevelopmentHubStep {
  WelcomeModal = 'welcome-modal',
  Overview = 'overview',
  Editing = 'editing',
  CreateInstance = 'create-instance',
  EvaluateFlow = 'evaluate-flow',
  FlowHistoryTable = 'flow-history-table',
}

export enum OnboardingEditorStep {
  Navigation = 'navigation',
}

export enum OnboardingEditorBasicStep {
  Save = 'save',
}

export enum OnboardingEditorModuleStep {
  WelcomeModal = 'welcome-modal',
  Save = 'save',
}

export enum OnboardingEditorSessionStep {
  WelcomeModal = 'welcome-modal',
  SidebarButton = 'sidebar-button',
  BackButton = 'back-button',
}

export enum OnboardingFlowInstanceStep {
  FlowViewHeader = 'flow-view-header',
  Modules = 'modules',
  Preview = 'preview',
  PlayButton = 'play-button',
}

export enum OnboardingFlowSessionStep {
  BackButton = 'back-button',
  CompleteButton = 'complete-button',
}

export type OnboardingStep =
  | OnboardingSuperflowStep
  | OnboardingWorkspaceStep
  | OnboardingTemplateStep
  | OnboardingTalentAcquisitionStep
  | OnboardingDevelopmentHubStep
  | OnboardingEditorStep
  | OnboardingEditorBasicStep
  | OnboardingEditorModuleStep
  | OnboardingEditorSessionStep
  | OnboardingFlowInstanceStep
  | OnboardingFlowSessionStep;

/**
 * Get the steps enum for a section.
 * @param section - The section to get the steps for.
 */
export function getOnboardingSectionEnum(section: OnboardingSection) {
  let usedEnum: object;
  switch (section) {
    case OnboardingSection.Superflow: {
      usedEnum = OnboardingSuperflowStep;
      break;
    }
    case OnboardingSection.Workspace: {
      usedEnum = OnboardingWorkspaceStep;
      break;
    }
    case OnboardingSection.Template: {
      usedEnum = OnboardingTemplateStep;
      break;
    }
    case OnboardingSection.TalentAcquisition: {
      usedEnum = OnboardingTalentAcquisitionStep;
      break;
    }
    case OnboardingSection.DevelopmentHub: {
      usedEnum = OnboardingDevelopmentHubStep;
      break;
    }
    case OnboardingSection.Editor: {
      usedEnum = OnboardingEditorStep;
      break;
    }
    case OnboardingSection.EditorBasic: {
      usedEnum = OnboardingEditorBasicStep;
      break;
    }
    case OnboardingSection.EditorModule: {
      usedEnum = OnboardingEditorModuleStep;
      break;
    }
    case OnboardingSection.EditorSession: {
      usedEnum = OnboardingEditorSessionStep;
      break;
    }
    case OnboardingSection.FlowInstance: {
      usedEnum = OnboardingFlowInstanceStep;
      break;
    }
    case OnboardingSection.FlowSession: {
      usedEnum = OnboardingFlowSessionStep;
      break;
    }
    default: {
      throw new Error(`Unknown section: ${section}`);
    }
  }

  return usedEnum;
}

/**
 * Get the index of a step in a section.
 * @param section - The section including the step.
 * @param step - The step to get the index for.
 */
export function getOnboardingStepIndex(
  section: OnboardingSection,
  step: string,
) {
  const usedEnum = getOnboardingSectionEnum(section);
  return Object.values(usedEnum).indexOf(step);
}

/**
 * Get the highest completed step index for a section by their step enum index.
 * @param section - The section to get the highest completed step for.
 * @param state - The completed steps by section object, usually provided by the state.
 */
export function getOnboardingHighestStepIndex(
  section: OnboardingSection,
  state: Record<string, IOnboardingSectionState>,
) {
  const usedEnum = getOnboardingSectionEnum(section);
  const completedSteps = state[section]?.completedSteps || [];
  if (!completedSteps) {
    return -1;
  }

  return Math.max(
    ...completedSteps.map((step) => Object.values(usedEnum).indexOf(step)),
    -1,
  );
}

/**
 * Get the highest available step index for a section by their step enum index.
 * @param section - The section to get the highest available step for.
 */
export function getOnboardingMaxStepIndex(section: OnboardingSection) {
  const usedEnum = getOnboardingSectionEnum(section);
  return Object.values(usedEnum).length - 1;
}
