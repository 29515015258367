import { Close } from 'src/app/components/icons';
import { modalCloseButtonCss } from 'src/app/components/modals/ModalCloseButton/ModalCloseButton.css';

interface IModalCloseButtonProps {
  onClick: () => void;
}

export function ModalCloseButton({ onClick }: IModalCloseButtonProps) {
  return (
    <button
      className={modalCloseButtonCss}
      aria-label="Close modal"
      onClick={onClick}
    >
      <Close />
    </button>
  );
}
