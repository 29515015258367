import { useModal } from '@ebay/nice-modal-react';
import { AnimatePresence } from 'framer-motion';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { AnimatedBody } from './AnimatedBody';
import { AnimatedOverlay } from './AnimatedOverlay';

type ReactModalOverlayElement = ComponentProps<
  typeof ReactModal
>['overlayElement'];
type ReactModalBodyElement = ComponentProps<
  typeof ReactModal
>['contentElement'];

interface IHookReturn {
  /**
   * `true` if a show or hide animation is in progress, and it's not safe
   * to unmount the modal component.
   */
  isAnimating: boolean;
  /**
   * This should be passed to the `isOpen` prop of the modal component
   * instead of conditionally rendering the whole component.
   *
   * Bumping this boolean to `false` allows triggering
   * the closing animation of the modal body and the overlay.
   */
  isModalVisible: boolean;
  /**
   * This is a render function that returns an invisible modal wrapper
   * animated with Framer Motion.
   * It should be passed to the `contentElement` prop of React Modal.
   */
  renderModalElement: ReactModalBodyElement;
  /**
   * This is a render function that returns an overlay element
   * animated with Framer Motion.
   * It should be passed to the `overlayElement` prop of React Modal.
   */
  renderOverlayElement: ReactModalOverlayElement;
}

/**
 * Coordinates a modal's exit animation and its unmounting,
 * ensuring that the animation is able to complete before the
 * modal disappears.
 *
 * The hook implements async methods, so chained modals wait
 * until until the previous modal disappears.
 *
 * Also renders custom overlay and modal body wrapper elements
 * animated with Framer Motion that can be passed to the appropriate props
 * of React Modal.
 * @see https://opensource.ebay.com/nice-modal-react
 * @see https://github.com/reactjs/react-modal
 * @param modalId - Unique modal ID passed to the `id` prop
 * of the modal component. The modal component should be created
 * through nice-modal-react's `create()` function.
 */
export default function useAnimatedModal(modalId: string): IHookReturn {
  const [isAnimating, setIsAnimating] = useState(false);
  const { remove, visible: isModalVisible, resolveHide } = useModal(modalId);
  useEffect(
    function triggerAnimationOnVisibilityChange() {
      setIsAnimating(true);
    },
    [isModalVisible],
  );
  return useMemo(
    () => ({
      isAnimating,
      isModalVisible,
      renderModalElement: (props, children) => (
        <AnimatedBody onAnimationEnd={() => setIsAnimating(false)} {...props}>
          {children}
        </AnimatedBody>
      ),
      renderOverlayElement: (props, children) => (
        <AnimatePresence
          onExitComplete={function unmountModal() {
            resolveHide();
            remove();
            setIsAnimating(false);
          }}
        >
          {isModalVisible && (
            <AnimatedOverlay
              onAnimationEnd={() => setIsAnimating(false)}
              {...props}
            >
              {children}
            </AnimatedOverlay>
          )}
        </AnimatePresence>
      ),
    }),
    [isAnimating, isModalVisible, remove, resolveHide],
  );
}
