import { PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingContext } from '@features/uiOnboarding/OnboardingContext';
import {
  getOnboardingHighestStepIndex,
  getOnboardingMaxStepIndex,
  getOnboardingStepIndex,
  OnboardingSection,
  OnboardingStep,
} from '@features/uiOnboarding/utils';
import {
  completeStep,
  selectCompletedStepsBySection,
  skipOnboarding,
  skipSection,
} from './state';

/**
 * The onboarding module is used to guide new users through the app. It can also
 * be used to guide users through the app after they have already used it, but
 * we implemented a new feature, and want to show them the new feature.
 *
 * In general, the onboarding module works with modals and specific tooltips.
 * It has components you can use, which all tie back into this provider. These
 * components are only rendering if they match the 1) current section, and 2)
 * the current step within that section.
 *
 * Progress is tracked through the provider, with each section having a step
 * counter. As of now, the progress lives in the local storage.
 */

/**
 * Provider for the onboarding flow.
 * @constructor
 */
export function OnboardingProvider({ children }: PropsWithChildren) {
  const dispatch = useDispatch();
  const completedStepsBySection = useSelector(selectCompletedStepsBySection);
  const [activeStep, setActiveStep] = useState<
    [OnboardingSection, OnboardingStep] | undefined
  >();

  const activateStep = useCallback(
    (section: OnboardingSection, step: OnboardingStep) => {
      if (!activeStep) {
        setActiveStep([section, step]);
      }
    },
    [activeStep],
  );

  const deactivateStep = useCallback(
    (section: OnboardingSection, step: OnboardingStep) => {
      if (activeStep && activeStep[0] === section && activeStep[1] === step) {
        setActiveStep(undefined);
      }
    },
    [activeStep],
  );

  const canActivateStep = useCallback(
    (section: OnboardingSection, step: OnboardingStep) => {
      if (activeStep && (activeStep[0] !== section || activeStep[1] !== step)) {
        return false;
      }

      if (completedStepsBySection[section]?.isSkipped) {
        return false;
      }

      const stepIndex = getOnboardingStepIndex(section, step);
      const highestStepIndex = getOnboardingHighestStepIndex(
        section,
        completedStepsBySection,
      );

      return stepIndex === highestStepIndex + 1;
    },
    [activeStep, completedStepsBySection],
  );

  const hasCompletedStep = useCallback(
    (section: OnboardingSection, step: OnboardingStep) => {
      const sectionInfo = completedStepsBySection[section];
      if (
        sectionInfo?.isSkipped ||
        sectionInfo?.completedSteps.includes(step)
      ) {
        return true;
      }

      const stepIndex = getOnboardingStepIndex(section, step);
      const highestStepIndex = getOnboardingHighestStepIndex(
        section,
        completedStepsBySection,
      );

      return stepIndex <= highestStepIndex;
    },
    [completedStepsBySection],
  );

  const isLastStep = useCallback(
    (section: OnboardingSection, step: OnboardingStep) => {
      const stepIndex = getOnboardingStepIndex(section, step);
      const maxStepIndex = getOnboardingMaxStepIndex(section);
      return stepIndex === maxStepIndex;
    },
    [],
  );

  const onCompleteStep = useCallback(
    (section: OnboardingSection, step: OnboardingStep) => {
      setActiveStep(undefined);
      dispatch(completeStep({ section, step }));
    },
    [dispatch],
  );

  const onSkipSection = useCallback(
    (section: OnboardingSection) => {
      setActiveStep(undefined);
      dispatch(skipSection({ section }));
    },
    [dispatch],
  );

  const onSkipOnboarding = useCallback(() => {
    setActiveStep(undefined);
    dispatch(skipOnboarding());
  }, [dispatch]);

  return (
    <OnboardingContext.Provider
      value={{
        activateStep,
        deactivateStep,
        canActivateStep,
        hasCompletedStep,
        isLastStep,
        completeStep: onCompleteStep,
        skipSection: onSkipSection,
        skipOnboarding: onSkipOnboarding,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

/**
 * Hook to get the onboarding context.
 */
export const useOnboardingContext = () => {
  const ctx = useContext(OnboardingContext);
  if (!ctx) {
    throw new Error('useOnboarding must be used within <OnboardingProvider />');
  }
  return ctx;
};
