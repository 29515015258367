import { createContext } from 'react';
import {
  OnboardingSection,
  OnboardingStep,
} from '@features/uiOnboarding/utils';

export interface IOnboardingContext {
  activateStep: (section: OnboardingSection, step: OnboardingStep) => void;
  deactivateStep: (section: OnboardingSection, step: OnboardingStep) => void;
  canActivateStep: (
    section: OnboardingSection,
    step: OnboardingStep,
  ) => boolean;
  hasCompletedStep: (
    section: OnboardingSection,
    step: OnboardingStep,
  ) => boolean;
  isLastStep: (section: OnboardingSection, step: OnboardingStep) => boolean;
  completeStep: (section: OnboardingSection, step: OnboardingStep) => void;
  skipSection: (section: OnboardingSection) => void;
  skipOnboarding: () => void;
}

/**
 * Context for the onboarding flow. Provides information about the current step,
 * and a function to mark a step as completed.
 */
export const OnboardingContext = createContext<IOnboardingContext>({
  activateStep: () => {},
  deactivateStep: () => {},
  canActivateStep: () => false,
  hasCompletedStep: () => false,
  isLastStep: () => false,
  completeStep: () => {},
  skipSection: () => {},
  skipOnboarding: () => {},
});
