import {
  IsArray,
  IsBoolean,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import type { LocaleMap, TextObject, UUID } from 'src/@types/common';
import { SidebarPage } from 'src/@types/session';
import {
  IsFirestoreUUID,
  IsLocaleText,
} from 'src/services/database/validators/validators';
import { CanvasDefaultContentDto } from './canvasDefaultContent.dto';
import { CanvasWidgetConfigDto } from './canvasWidgetConfig.dto';
import { SessionActivityDto } from './sessionActivity.dto';
import { SurveyConfigDto } from './surveyConfig.dto';

export class CanvasSessionDto {
  /** Unique identifier of the session definition */
  @IsFirestoreUUID()
  readonly id: UUID;

  // TODO update flow progress calculation to exclude hidden sessions
  /**
   * If `true`, the session is accessible to visitors of the flow on the session page,
   * and in the module list of the flow instance page.
   *
   * People who can edit the parent flow will always have access to the session.
   *
   * Data of hidden sessions (e.g. duration or user progress) shouldn't be included
   * when aggregating flow-level data.
   * @default true
   */
  @IsOptional()
  @IsBoolean()
  readonly isVisible?: boolean;

  /**
   * Each activity definition includes what happens in the session if that activity is active.
   * This includes any of the following:
   * - the nudge video config
   * - the nudge text
   * - setting the active sidebar page
   * - toggling outcome selection
   * - highlighting canvas items.
   */
  @IsArray()
  @ValidateNested({ each: true })
  readonly activities: SessionActivityDto[];

  /**
   * Canvas widgets (e.g. drop zones, tables, text blocks) are instantiated based on these config objects
   * at the moment the flow instance is created for every canvas.
   *
   * From that point, each canvas element will have its own live `CanvasContent` document
   * based on these initial settings.
   */
  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  readonly canvasWidgetConfigs?: CanvasWidgetConfigDto[];

  /**
   * Any regular canvas content that should exist right away on the canvas.
   * These are instantiated based on these config objects at the moment
   * the flow instance is created for every canvas
   */
  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  readonly canvasDefaultItems?: CanvasDefaultContentDto[];

  /**
   * Whether the user can create outcomes that are displayed in the next session.
   * Outcomes can be canvas items, or e.g. strings entered in forms.
   *
   * TODO in the editor both outcome selection and the sidebar panel should be controlled by `activities`.
   * (Available sidebars may need their own property.)
   */
  @IsOptional()
  @IsBoolean()
  readonly canHaveOutcomes?: boolean;

  /**
   * Whether the user can import outcomes created in other sessions.
   * Outcomes can be canvas items, or e.g. strings entered in forms.
   * TODO in the editor the sidebar panel should be toggled by `activities`.
   * (Available sidebars may need their own property.)
   */
  @IsOptional()
  @IsBoolean()
  readonly canHavePreviousOutcomes?: boolean;

  /** Describes which modal should be rendered when the user clicks the "Complete session" button */
  @IsOptional()
  @IsString()
  readonly completionModalId?: string;

  /**
   * The session's duration in minutes, as estimated by the flow author.
   *
   * This information is displayed to the user, indicating how long it takes
   * to complete the session after watching all videos and completing all activities.
   */
  @IsNumber()
  readonly duration: number;

  /** The session's icon is displayed in the flow's module list. */
  @IsString()
  readonly icon: string;

  /**
   * ID of the flow module which "contains" this session definition.
   *
   * At this point, modules are only used for visual grouping. In the database,
   * sessions are siblings of the flow modules, NOT their children.
   *
   * TODO try deprecating; seems unnecessary
   */
  @IsFirestoreUUID()
  readonly moduleId: UUID;

  /** _(not implemented yet)_ List of the sidebar pages available for participants during the live session. */
  @IsOptional()
  @IsArray()
  @IsEnum(SidebarPage, { each: true })
  readonly sidebarPages?: SidebarPage[];

  /**
   * List of survey modal definitions to be triggered at the beginning or end of a session.
   */
  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  readonly surveys?: SurveyConfigDto[];

  /** Localized intro text displayed in an overlay over canvas previews as stringified HTML. */
  @IsOptional()
  @IsLocaleText()
  readonly teaser?: LocaleMap<TextObject>;

  /**
   * Localized session title presented to the user.
   */
  @IsLocaleText()
  readonly title: LocaleMap<TextObject>;

  /** AI prompt for the session */
  @IsString()
  @IsOptional()
  readonly aiPrompt?: string;

  constructor(dto: CanvasSessionDto) {
    this.activities = dto.activities.map(
      (activity) => new SessionActivityDto(activity),
    );
    this.canHaveOutcomes = dto.canHaveOutcomes;
    this.canHavePreviousOutcomes = dto.canHavePreviousOutcomes;
    this.canvasDefaultItems = dto.canvasDefaultItems?.map(
      (item) => new CanvasDefaultContentDto(item),
    );
    this.canvasWidgetConfigs = dto.canvasWidgetConfigs?.map(
      (item) => new CanvasWidgetConfigDto(item),
    );
    this.completionModalId = dto.completionModalId;
    this.duration = dto.duration;
    this.icon = dto.icon;
    this.id = dto.id;
    this.isVisible = dto.isVisible ?? true;
    this.moduleId = dto.moduleId;
    this.sidebarPages = dto.sidebarPages;
    this.surveys =
      dto.surveys?.map((survey) => new SurveyConfigDto(survey)) ?? [];
    this.teaser = dto.teaser;
    this.title = dto.title;
    this.aiPrompt = dto.aiPrompt;
  }
}
