import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { OnboardingModal } from '@features/uiOnboarding/Modal/OnboardingModal';
import {
  OnboardingSection,
  OnboardingSuperflowStep,
} from '@features/uiOnboarding/utils';
import { useOnboardingContext } from '@features/uiOnboarding/OnboardingProvider';
import { unstyledButton } from 'src/styles/utils.css';
import { linkText } from 'src/styles/global.css';

/**
 * Superflow onboarding component that renders a welcome modal for new users.
 */
export function SuperflowOnboarding({ children }: PropsWithChildren) {
  const { skipOnboarding } = useOnboardingContext();

  return (
    <>
      {children}

      <OnboardingModal
        section={OnboardingSection.Superflow}
        step={OnboardingSuperflowStep.WelcomeModal}
        modalTitle="Welcome to Superflow 👋"
        modalActionButtonText="Get started"
      >
        <p>
          We are excited to have you on board! Superflow is a powerful tool that
          can help you streamline talent acquisition, and talent development.
        </p>
        <p>
          Let us guide you through the process of using your workspace. If you
          {"'"}ve already used Superflow, you can
          <button
            className={clsx(unstyledButton, linkText)}
            type="button"
            onClick={() => skipOnboarding()}
            style={{ display: 'inline' }}
          >
            skip onboarding.
          </button>
        </p>
      </OnboardingModal>
    </>
  );
}
