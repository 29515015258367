import { create, register } from '@ebay/nice-modal-react';
import clsx from 'clsx';
import {
  ComponentProps,
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import ReactModal from 'react-modal';
import { defaultTheme, modalWrapper } from './AnimatedModal.css';
import useAnimatedModal from './useAnimatedModal';

type AllowedReactModalProps = Omit<
  ComponentProps<typeof ReactModal>,
  'isOpen' | 'id' | 'onRequestClose' | 'overlayElement' | 'contentElement'
>;

interface IAnimatedModalProps {
  className?: string;
  /** Unique ID used for registering the component with the modal manager. */
  modalId: string;
  /**
   * Optional props passed through to React Modal.
   * @see https://reactcommunity.org/react-modal/#usage
   */
  modalProps?: AllowedReactModalProps;
  /**
   * Called when the modal body or the modal overlay detects user intent
   * to close the dialog. In that case, the parent should simply flip
   * `isOpen` to `false` and the hide animation will begin.
   */
  onRequestClose: () => void;
  style?: CSSProperties;
  /**
   * Custom theme definition class names should be passed to this prop,
   * so CSS variables can be propagated to both the overlay and the modal body
   * within the created portal.
   *
   * To customize the modal theme, use the `modalVars` export as a base
   * for generating a Vanilla Extract theme.
   * @see https://vanilla-extract.style/documentation/api/create-theme/#creating-theme-variants
   */
  themeClassName?: string;
}
const ManagedModal = create(ReactModal);

/**
 * Provides a pre-configured React Modal wrapper for any content.
 *
 * The component renders animated overlay and modal body components,
 * and can be chained with other dialogs managed by Nice Modal React.
 *
 * To properly animate chaining modals, parent components must use
 * imperative logic to show/hide modals, as opposed to nice, declarative props.
 *
 * @example
 * ```typescriptreact
 * import { useModal } from '@ebay/nice-modal-react'
 * const id = 'id'
 * const modalHandler = useModal(id)
 * // ...
 * return ()=> <AnimatedModal modalId={id} onRequestClose={modalHandler.hide} {...} />
 * ```
 */
export function AnimatedModal({
  children,
  className,
  modalId,
  modalProps = {},
  onRequestClose,
  themeClassName,
}: PropsWithChildren<IAnimatedModalProps>) {
  // Allow Nice Modal React to manage the rendered component even if it isn't rendered
  const [hasRegistered, setHasRegistered] = useState(false);
  useEffect(
    function registerModal() {
      if (!hasRegistered) {
        register(modalId, ManagedModal);
        setHasRegistered(true);
      }
    },
    [hasRegistered, modalId],
  );

  const {
    renderModalElement,
    renderOverlayElement,
    isAnimating,
    isModalVisible,
  } = useAnimatedModal(modalId);

  return isModalVisible || isAnimating ? (
    <ReactModal
      portalClassName={clsx(defaultTheme, themeClassName)}
      overlayElement={renderOverlayElement}
      contentElement={renderModalElement}
      isOpen // this must remain `true` because we control whether the modal is visible
      onRequestClose={onRequestClose}
      className={clsx(modalWrapper, className)}
      {...modalProps}
    >
      {children}
    </ReactModal>
  ) : null;
}
